import { useEffect, useState } from "react";
import { useUserSettings } from "../../components/UserSettingsProvider/UserSettingsProvider";
import ResidentsTable from "../../components/AQDataTable/ResidentsTable";
import { apiGet, apiPost } from "../../data/apidata";
import { filter } from "d3";
import { getOnceValue } from "../../data/firebase";
import AQDataTable from "../../components/AQDataTable/AQDataTable";

const NourishMonitor = () => {

  const { userSettings, updateSettings } = useUserSettings();
  const [site, setSite] = useState("");
  const [rooms, setRooms] = useState([]);
  const [options, setOptions] = useState(null);
  const [isOptionsLoaded, setIsOptionLoaded] = useState(false);


  function getNourishResidentsList() {
    // getUnitOptions();
    if (isOptionsLoaded)
      apiPost("api/messages/residents/list",
        { "siteID": userSettings.site.id },
        (data) => {
          let tmp = []
          if (Array.isArray(data.message)) {
            if (data.message.length > 0) {
              data.message.map(item => {
                if (item.dob != null && item.nhsNumber != null)
                  if (item.dob.length > 0 && item.nhsNumber.length > 0)
                    tmp.push(item)
              })

            }

            const res = setRoomNamesAndZones(tmp)

            console.log('checkNM', res)
            setRooms(res);
          }
          else {
            setRooms([]);
          }


        }, (e) => { console.log(e) }

      );
  }
  useEffect(() => { getUnitOptions(); })
  useEffect(() => {
    getNourishResidentsList()
  }, [isOptionsLoaded])

  function getSiteFromUserObject(userObj) {
    try { return userObj.site.code }
    catch (e) { return ""; }
  }

  function checkUserSite() {
    const ste = getSiteFromUserObject(userSettings);
    if (ste !== site) setSite(ste);
  }

  if (site === "") checkUserSite();

  function getUnitOptions() {
    if (!isOptionsLoaded) {
      getOnceValue(`${userSettings.site.code}config`, (config) => {
        if (config === null) {
          setOptions(null);
          return;
        }
        const cnf = config.filter(od => od.unitId !== undefined);
        let optionsData = cnf.map(configRec => { return ({ "unitCode": configRec.unitId, "value": configRec.unitName, "zone": configRec.unitZone }) });
        setOptions(optionsData);
        setIsOptionLoaded(true);
        // console.log(optionsData)
      }, (e) => { console.log(e) });
    }
  }

  function setRoomNamesAndZones(tmp_rooms) {
    if (tmp_rooms === null) return;
    if (options === null) return;
    let rr = []
    if (tmp_rooms.length > 0) {
      rr = (tmp_rooms.map(r => {
        return {
          ...r,
          Room: getRoomRowValue(r.RoomUnits, options).join(", "),
          Zone: getZoneRowValue(r.RoomUnits, options).join(", "),
        }
      })
      )

      console.log("Room name", rr)
    }
    return rr;
  }

  // useEffect(() => { getNourishResidentsList(); getUnitOptions(); }, [site]);
  // useEffect(() => {
  //   setRoomNamesAndZones();
  // }, [options])
  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      size: 150,
    },
    {
      accessorKey: 'firstName',
      header: 'First Name',
      size: 150,
    },
    {
      accessorKey: 'surname',
      header: 'Surname',
      size: 150,
    },
    {
      accessorKey: 'Room',
      header: 'Room',
      size: 150,

      // Cell: ({ renderedCellValue, row }) => (<div className="flex-row">{getRoomRowValue(renderedCellValue, options).join(", ")}</div>)
    },
    {
      accessorKey: 'Zone',
      header: 'Zone',
      size: 150,
      // Cell: ({ renderedCellValue, row }) => (<div>{getZoneRowValue(renderedCellValue, options).join(", ")}</div>),

    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 150,
    }
  ];


  return (
    <div>

      <h4>Residents</h4>

      {(options === null)
        ? <div>Unable to find a configuration for this site.</div>
        : <ResidentsTable
          data={rooms}
          columns={columns}
          title={"residents_table"}
          siteID={userSettings.site.id}
          dataRefresh={getNourishResidentsList}
          options={options}
        />
      }

    </div>
  );
}

export function getRoomRowValue(rows, options) {
  if ((rows === null) || (rows === undefined)) return [];
  if ((options === null) || (options === undefined)) return [];
  if (rows.length === 0) return [];
  return rows.map(r => {
    const match = options.filter(o => o.unitCode === r);
    return (match.length > 0) ? match[0].value : r;
  })
}

export function getZoneRowValue(rows, options) {
  if ((rows === null) || (rows === undefined)) return [];
  if ((options === null) || (options === undefined)) return [];
  if (rows.length === 0) return [];
  let rtn = rows.map(r => {
    const match = options.filter(o => o.unitCode === r);
    return (match.length > 0) ? match[0].zone : r;
  });
  rtn = removeDuplicates(rtn);
  return rtn;
}

function removeDuplicates(array) {
  // Use Set to remove duplicates and convert back to array
  return [...new Set(array)];
}

export default NourishMonitor;