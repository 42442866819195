import {
  MaterialReactTable,
  //  MRT_EditActionButtons, 
  useMaterialReactTable,
  //  MRT_ColumnDef
} from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { Box, Button, ListItemIcon, MenuItem, IconButton, Menu, SvgIcon } from '@mui/material';
import {
  //  Edit as EditIcon,
  //  Delete as DeleteIcon,
  //  Email as EmailIcon,
  //  Search as ViewIcon,
  AccountCircle,
  //  Send,
  Link
} from '@mui/icons-material';
import AQModal from '../AQModal/AQModal';
import { useEffect, useState } from 'react';
import ExportHandler from '../../Pages/Exports/export_handle';
//import { getFilename } from './tableFunctions';

import AQToolTip from '../ToolTip/ToolTip';
//import { FormInput } from '../Input';
import Tagging from '../Tagging/Tagging';
import {
  //apiGet, 
  apiPost
} from '../../data/apidata';
//import { getOnceValue } from '../../data/firebase';//
import {
  getRoomRowValue,
  getZoneRowValue
} from '../../Pages/Nourish/NourishMonitor';
import { MasterTableSettings } from './AQDataTable';
import "../AQDataTable/AQDataTable.css"
// import threeDots from "../../images/settings/threeDots.png";
import { NourishResidentEdit } from "../../Pages/Nourish/NourishResidentEdit.js"
import { NourishResidentDetails } from '../../Pages/Nourish/NourishResidentDetails.js';


const ResidentsTable = ({ data, columns, title, onUpdateRefreshPath, dataRefresh, siteID, options }) => {

  const [selectedResident, setSelectedResident] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowID, setRowID] = useState(null);
  const [modalElement, setModalElement] = useState(null);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.target);
    setRowID(id)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setRowID(null);
  };


  const table_settings = {
    ...MasterTableSettings,
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        {//<button className='button_aqtable'>Create New Resident</button>
        }
        <ExportButton tableData={table} />
      </div>
    ),
    renderRowActions: ({ row,
      //closeMenu 
    }) => (
      <div className='buttonLayout'>
        <AQToolTip className="menuButton" tooltip={<div className="tooltipStyle">View In Nourish</div>} >
          <IconButton className='menuButton'
            color="primary"
            onClick={() => window.open(row.original.clientSystemUrl)}
          >
            <Link style={{ color: 'gray' }} />
          </IconButton>
        </AQToolTip>
        <IconButton color='primary' className=' menuButton' onClick={(t) => handleMenuOpen(t, row.original.id)}>
          <SvgIcon sx={{
            color: '#2e2d2d',
          }}
            className='ThreeDots' focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreHorizIcon"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"></path></SvgIcon>
          {/* <div ><span className='ThreeDots'>...</span></div> */}
          {/* <img src={threeDots} alt="..." /> */}
        </IconButton>

        <Menu
          className="ActionMenuShadow" i
          anchorEl={anchorEl}
          open={Boolean(rowID === row.original.id)}
          onClose={handleMenuClose}
        >
          {/* <MenuItem
            key={0}
            onClick={() => {
              handleMenuClose();
              // setModalElement(<UserDetails onClose={closeModal}  user={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options}/>)
              setModalElement(<NourishResidentDetails onClose={closeModal} userData={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)

            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><AccountCircle /></ListItemIcon>
            View Details
          </MenuItem>
          {console.log()} */}
          <MenuItem
            key={0}
            onClick={() => {
              // setSelectedResident(row.original);user, siteID, dataRefresh, options, onClose
              handleMenuClose();
              setModalElement(<UserDetails onClose={closeModal} user={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><AccountCircle /></ListItemIcon>
            Edit Details
          </MenuItem>
        </Menu>
      </div>
    ),



  };

  function exportdataClean(data) {

    // console.log('checkExport', data)

    return data.map(d => {
      console.log('checkData', options)
      return ({
        "Title": d.title,
        "First Name": d.firstName,
        "Surname": d.surname,
        "Room": getRoomRowValue(d.RoomUnits, options).join(" - "),
        "Zone": getZoneRowValue(d.RoomUnits, options).join(" - "),
        'Status': d.status
      })
    });

  }

  const ExportButton = ({ tableData }) => {

    const [exportData, setExportData] = useState(null);

    return (
      <>
        {
          (exportData !== null) && setModalElement(<ExportHandler title={"Residents"} data={exportData} onClose={() => { closeModal(); setExportData(null) }} masterData={exportData} show={(exportData !== null)} processor={"residents_list"} options={options} />)
        }
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            onClick={() => {
              const rows = ((tableData.getIsAllRowsSelected()) ? tableData.getSelectedRowModel().rows : tableData.getSelectedRowModel().rows);
              const rowData = rows.map((row) => row.original);

              if ((rows !== null) && (rows.length > 0)) {
                // setExportData(exportdataClean(rowData));
                setExportData(rowData);
              }
              else {
                // setExportData(exportdataClean(data));
                setExportData(data);
              }
            }}
            disabled={tableData.getSelectedRowModel().rows.length === 0}
            startIcon={<FileDownloadIcon />}
          ></Button>
        </Box>
      </>
    );
  }

  const table = useMaterialReactTable({ columns, data, initialState: { pagination: { pageSize: 50, pageIndex: 0 } }, ...table_settings });


  function closeModal() {
    setSelectedResident(null);
    setModalElement(null)
    if (onUpdateRefreshPath !== undefined) {
      if (dataRefresh) dataRefresh();
    }
    else {
      if (dataRefresh) dataRefresh();
    }
  }

  return (
    <div>
      {
        (modalElement !== null)
        &&
        <AQModal show={true} onClose={closeModal} >
          {modalElement}
        </AQModal>
      }
      <div className="usermanagement_list_container"><MaterialReactTable table={table} /></div>
    </div>
  );

};


const PaperProps = {
  elevation: 0, //change the mui box shadow
  //customize paper styles
  sx: {
    borderRadius: '0',
    //border: '1px dashed #e0e0e0',
  },
};



const UserDetails = ({ user, siteID, dataRefresh, options, onClose }) => {


  console.log('row', user)
  return (
    <div className='aq_form user_detail_container'>
      <h3>User Details</h3>
      <div>This Resident is integrated with Nourish. <a target='new' href={user.clientSystemUrl}>View details in Nourish</a></div>
      <div className='aq_grid-container'>

        <div class="aq_grid-item aq_left-column">
          <DetailsCell title={"Name"} details={`${user.title} ${user.firstName} ${user.surname}`} />
        </div>

        <div class="aq_grid-item aq_right-column">
          <DetailsCell title={"Date of Birth"} details={user.dob} />
        </div>

        <div class="aq_grid-item aq_left-column">
          <DetailsCell title={"NHS Number"} details={user.nhsNumber} />
        </div>

        <div class="aq_grid-item aq_right-column">
          <DetailsCell title={"Gender"} details={user.gender} />
        </div>
        {/*
      <div class="aq_grid-item aq_left-column">
        <DetailsCell title={"Preferred Name"} details={user.preferredName} />
      </div>
*/}
      </div>

      <hr />
      <br />
      <UserRoomAssignment user={user} units={user.RoomUnits} siteID={siteID} onChange={dataRefresh} options={options} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='validate_close_div'>
          <button className='close_buton' onClick={onClose}>Close</button>
        </div>
      </div>
    </div>

  );

}

const DetailsCell = ({ title, details }) => {
  return (
    <div>
      <div>{title}</div>
      <div className='details'>{details}</div>
    </div>
  );
}

const UserRoomAssignment = ({ user, units, siteID, onChange, options }) => {
  const [selections, setSelections] = useState([]);

  function removeTag(id) {
    dbRemoveTag({ ...user, ...{ "siteID": siteID, "unitCode": id } }, () => {
      setSelections(selections.filter(f => (f.unitCode !== id)))
    })
  }

  function dbAddTag(data, onUpdated) {
    data.source = "Nourish"
    apiPost("api/messages/residents/resident/addunit", data, onUpdated(), (e) => { console.log(e) })
  }

  function dbRemoveTag(data, onUpdated) {
    apiPost("api/messages/residents/resident/removeunit", data, onUpdated(), (e) => { console.log(e) })
  }

  function addTag(id) {
    console.log('tag data', { ...user, ...id, ...{ "siteID": siteID } })
    dbAddTag({ ...user, ...id, ...{ "siteID": siteID } }, () => {
      const newSelections = [...selections];
      newSelections.push(id);
      setSelections(newSelections);
    })
  }


  function getOptionValue(matchVal) {
    try {
      return options.filter(optionRecord => (optionRecord.unitCode === matchVal))[0].value;
    }
    catch (e) { return "" }

  }

  function buildSelections() {
    if (units.length > 0) {
      let tmpUnits = units.map(unitRecord => {
        return ({
          "unitCode": unitRecord,
          "value": getOptionValue(unitRecord)
        })
      })
      setSelections(tmpUnits)
    }
  }

  useEffect(() => {
    //    if (options.length===0) getUnitOptions();
  }, [siteID])


  useEffect(() => buildSelections(), [options])


  if (options === undefined) return ("No room units config")

  return (
    <div className='aq_room_div'>
      <h5>Assign Rooms</h5>
      <small>Press enter to add</small>
      <Tagging options={options} selections={selections} onRemove={removeTag} onAdd={addTag} />
    </div>

  );
}

export default ResidentsTable;