import React, { useEffect, useState } from 'react';
import { FormInput } from '../Input';
import './NewUserPage.css';
import { apiPost } from '../../data/apidata';
import { useSnackbar } from 'notistack';
import { SiteAssigment } from '../../Pages/Settings/UserManagement/connectUserEdit';

const NewUserPage=({siteID, onClose, canAssign})=>{

    const { enqueueSnackbar } = useSnackbar();
    const [userAdded, setUserAdded] = useState(false);
    const [userDetails, setUserDetails] = useState([]);

    const [formData, setFormData] = useState({
        siteID: `${siteID}`,
        title:"",
        "role": "Carer"
    });
    const [passwordError, setPasswordError] = useState("");
    const [error, setError] = useState("");
    console.log('new user siteID',siteID);
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

    function siteChange(val) {
        console.log("siteChange", val);
        setFormData((prevData) => ({
        ...prevData,
        ["siteID"]: val,
        }));
    };
    

    function cleanError(message){
        console.log({message});
        if (message.indexOf("Object didn't pass validation for format email:")>-1) return `Invalid email address format.`
        return message;
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform actions with formData here, such as sending it to an API
        console.log('Form data submitted:', formData);

        apiPost(
            "api/messages/site/users/add",
            formData,
            (d)=>{ 
                
                 apiPost("api/messages/user/site/getUserFromEmail",
                {
                    "email":formData.email,
                },
                    (d)=>{
                console.log(d['message']['data'][0]);
                setUserDetails(d['message']['data'][0]);
                setUserAdded(true);
                setError('');
                setPasswordError('')
                if(canAssign===false)
                {
                    onClose();
                    enqueueSnackbar("User created", {variant:"success"});
                }
                        console.log('User added',d)
                    },
                    (e)=>{console.log('Error',e)}
                )
                //if (onClose) onClose();
            },
            (e)=>{
                console.log("Error", e);
                if (e.toString().indexOf("Password")>-1) {
                    setPasswordError(e);
                }
                else {
                    if (e==="##ERROR##::siteID is not a number.") {
                        setError("You have not selected a site to assign the user to.");
                    }
                    else {
                        setError(e);
                    }
                }
            }
        )

    };
               
    return (
        <div className='new_user_page_container'>
            <h1>New User</h1>
            <form className='aqForm' onSubmit={handleSubmit}>
            {(error!=="")&&<><small className='formFieldError'>{error}</small><br /></>}
            <div> {userAdded===false?
            <div className='aq_fields'>
                {canAssign===true?
                 <h4 style={{marginTop:0}}>Step 1/2: Fill in user details</h4>
                 :null}
            <label>
            Title:
            <TitleInput
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
            />
            </label>
            <br />

            <label>
            Name:
            <FormInput
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
            />
            </label>
            <br />
            <label>
            Surname:
            <FormInput
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
            />
            </label>
            <br />
            <label>
            Email:
            <FormInput
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
            />
            </label>
            
            <br />

            <label>
            Password:&nbsp;&nbsp;&nbsp;
            {(passwordError!=="")&&<small className='formFieldError'>{passwordError}</small>}
            <FormInput
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
            />
            
            </label>
            <br />
            <label>
                
            Role:
            <SelectInput
                name="role"
                value={formData.role}
                onChange={handleChange}
            />
            </label>
            {(siteID===null)&&
            <>
            <br />
            </>
            }
            
            </div>
        :null}
            </div>
            {userAdded===true?
            <div>
                <h4 style={{marginTop:0}}> Step 2/2: Assign user sites</h4>
                <SiteAssigment email={formData.email} userID={userDetails.u_id}/>
            </div>

            :null}
            
            
            <div className='submit_fields'>
            <div className='validate_close_div'>
                {userAdded===false?
                <button className='validate_button' type="submit">Create user</button>
                
            :null}
                <button className='close_buton' onClick={
                    ()=>{
                        if(userAdded){
                            enqueueSnackbar("User created", {variant:"success"});
                        }
                        onClose()
                    }
                    }>Close</button>
            </div>
            </div>
 
        </form>
        </div>
    );
};


export default NewUserPage;


const SelectInput=({name, value, onChange})=>{

    return(
        <select 
            name={name} 
            className='aqselect'
            onChange={onChange}
        >
            <option selected={(value==="Manager")} value="Manager">Manager</option>            
            <option selected={(value==="Carer")} value="Carer">Carer</option>
            <option selected={(value==="Maintenance")} value="Maintenance">Maintenance</option>
        </select>
    )
}

const TitleInput=({name, value, onChange})=>{

    return(
        <select 
            name={name} 
            className='aqselect'
            onChange={onChange}
        >
            <option selected={(value==="")} value=""></option>
            <option selected={(value==="Mr")} value="Mr">Mr</option>            
            <option selected={(value==="Mrs")} value="Mrs">Mrs</option>
            <option selected={(value==="Ms")} value="Ms">Ms</option>
        </select>
    )
}


const SelectSite=({name, value, onChange, userID})=>{

    const [sitesList, setSitesList] = useState([]);
    const [selectedSite, setSelectedSite] = useState(value);
    
    function onError(e){

    }

    useEffect(()=>{ if (onChange) onChange(selectedSite); }, [selectedSite])
    useEffect(()=>{

        apiPost(
            "api/messages/user/site/listAll",
            {
                "userID" : `${userID}`,
                "siteID" : "pass1"
            },
            (d)=>{ 
                const data = d.message.data;
                console.log(data);
                setSitesList(data);
            },
            (e)=>{ onError(e) },
        )

    }, [sitesList.length===0])

    function setSelected(e){
        setSelectedSite(e.target.value);
    }

    return(
        <select 
            name={name} 
            className='aqselect'
            onChange={setSelected}
        >
        
        <option selected={(selectedSite==="")} value=""></option>
        {
        sitesList.map(s=><option selected={(selectedSite===s.id)} value={s.id}>{s.name}</option>)
        }

        </select>
    )
}