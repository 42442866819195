import { useEffect, useState } from 'react';
import { useModal } from '../../components/ModalContext/ModalContext';
import { Bar } from 'react-chartjs-2';
import BarChart from '../../components/AverageResponseChart/BarChart';
import { InsightDataModal } from '../../components/charts/DataModal';

const Insight_BarChartCustom = ({ barData, barKeys, barOptions, selectedData, setSelectedData, columns, barType }) => {

  const { isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if (selectedData === null) {
      closeModal();
    }
    else {
      openModal();
    }
  }, [selectedData]);

  return (
    <div>
      {(isModalOpen && (selectedData !== null)) && (
        <InsightDataModal
          title={(selectedData[0].callType) ? selectedData[0].callType : ''}
          selectedData={(selectedData) ? selectedData : null}
          columns={columns}
          closeModal={closeModal}
          setSelectedData={setSelectedData}>
        </InsightDataModal>
      )}
      {
        (barType === 'BarChart') ? (
          <BarChart datasets={barData} options={barOptions} />
        ) : (
          <Bar data={barData} keys={barKeys} options={barOptions} />
        )
      }
    </div>
  );
}

export default Insight_BarChartCustom