import { createMRTColumnHelper } from 'material-react-table';
import { JourneyBar } from '../charts/JourneyBar/JourneyBar';
import CareClicksField from '../CareClicksField/CareClicksField';
import ZoneProfileCheckBox from '../ZoneProfileCheckBox/ZoneProfileCheckBox';
import "./AQDataTable.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { GetHeartbeatIcons } from '../GetImage';


const columnHelper = createMRTColumnHelper();

export const columns_template_reporting_calls = [
  // columnHelper.accessor('callType', {
  //   header: 'Call Origin',
  //   size: 40,
  //   filterVariant: 'multi-select',
  //   filterSelectOptions: ["Emergency", "Call", "Attendance", "Assistance", "Sense"],
  // }),
  
  columnHelper.accessor('DeviceType', {
    header: 'Call Source',
    size: 40,
    filterVariant: 'multi-select',
    filterSelectOptions: ["Room Unit", "Pendant", "Motion Point", "Sensor Mat Unit", "Doorbell", "Ceiling Pull Cord", "Door Contact", "Sense"],
  }),
  columnHelper.accessor('callText', {
    header: 'Call Types',
    size: 40,
    filterVariant: 'multi-select',
    filterSelectOptions: ["Emergency", "Call", "Attendance", "Assistance", "Sense"],
  }),
  columnHelper.accessor('room', {
//accessorKey: 'roomsss',
    header: 'Room',
    size: 40,
//    filterVariant: 'multi-select',
//    filterSelectOptions: ["Bedroom 15", "Bedroom 8", "Bedroom 4", "Bedroom 1", "Bedroom 2"],
  }),

  columnHelper.accessor('zone', {
    header: 'Zone',
    size: 40,
  }),

  columnHelper.accessor('calls', {
    header: 'Journey ',
    size: 150,
    Cell: ({ renderedCellValue, row }) =><JourneyBar journey={renderedCellValue} totalSeconds={row.original.durationSeconds} />,
  }),

  columnHelper.accessor('start', {
    header: 'Start',
    size: 100,
    Cell: ({ cell }) =><div>{utcDateToHHMMYYYYHHMM(cell.getValue())}</div>,
  }),
  
  columnHelper.accessor('end', {
    header: 'End',
    size: 100,
    Cell: ({ cell }) =><div>{utcDateToHHMMYYYYHHMM(cell.getValue())}</div>
  }),

  columnHelper.accessor('duration', {
    header: 'Duration',
    size: 40,
  }),

  columnHelper.accessor('Carer', {
    header: 'Carer',
    size: 40,
    Cell: ({ renderedCellValue }) =><div>{renderedCellValue}</div>
  }),
  columnHelper.accessor('AQRef', {
    header: 'AQRef',
    size: 40,
  }),
  columnHelper.accessor('JourneyRef', {
    header: 'JourneyRef',
    size: 40,
  }),
  columnHelper.accessor('SiteID', {
    header: 'SiteID',
    size: 40,
  }),
  columnHelper.accessor('Site', {
    header: 'Site',
    size: 40,
  }),
  columnHelper.accessor('care_delivered', {
    header: 'Care Delivered',
    size: 40,
    Cell: ({ renderedCellValue, row }) => (
      <div><CareClicksField careClicks={renderedCellValue} /></div>
    ),
  }),
];


export const columns_template_battery_monitoring_calls = [

  columnHelper.accessor('unitId', {
    header: 'Unit ID',
    size: 40,
  }),

  columnHelper.accessor('roomName', {
    header: 'Name',
    size: 40,
    Cell: ({ renderedCellValue, row })=>{
      return (<div>{((renderedCellValue===null)||(renderedCellValue===""))?"Room unit not configured":renderedCellValue}</div>);
    },
  }),


  columnHelper.accessor('rm_zone_name', {
    header: 'Zone',
    size: 40,
  }),

  columnHelper.accessor('charging', {
    header: 'Battery',
    size: 40,
    Cell: ({ renderedCellValue, row })=>{
      if (row.original.text==="Charging") return (<div style={{"color":"green"}}>{row.original.text}</div>);
      if (row.original.text==="Low Battery") return (<div style={{"color":"red"}}>{row.original.text}</div>);
      return (<div>{row.original.text}</div>);
//      if (row.original.charging===1) return (<div style={{"color":"green"}}>Charging</div>);
/*      if (row.original.level===1) {
        return (<div style={{"color":"red"}} >Low Battery</div>);
      } 
      else {
        return (<div></div>);
      }
      */
    },
  }),

  columnHelper.accessor('date_time', {
    header: 'Last reported',
    size: 40,
    Cell: ({ renderedCellValue, row }) => (
      <div>{ utcDateToHHMMYYYYHHMM(renderedCellValue)}</div>
    ),
  }),

];


export const columns_template_devices = [
    columnHelper.accessor('unitId', {
      header: 'Room unit code',
      size: 40,
    }),
    columnHelper.accessor('unitName', {
      header: 'Unit name',
      size: 120,
    }),
    columnHelper.accessor('unitZone', {
      header: 'Zone',
      size: 120,
    }),
    columnHelper.accessor('move_id', {
      header: 'Move ID',
      size: 300,
    }),
    columnHelper.accessor('groupid', {
      header: 'Group ID',
    })
  ];

  export const columns_template_nourishcalls = [
  
    columnHelper.accessor('journeyRef', {
      header: 'Room unit code',
      //size: 40,
    }),
    columnHelper.accessor('aqRef', {
      header: 'Call ref',
      //size: 40,
    }),
    columnHelper.accessor('callType', {
      header: 'Call type',
      //size: 40,
    }),
    columnHelper.accessor('room', {
      header: 'Room unit code',
      //size: 40,
    }),
    columnHelper.accessor('start', {
      header: 'Room unit code',
      //size: 40,
    }),
    columnHelper.accessor('unitId', {
      header: 'Room unit code',
      //size: 40,
    }),
    columnHelper.accessor('nourishID', {
      header: 'Nourish ID',
      //size: 40,
    }),
    
  ];


  export const columns_template_nourishresidentslist = [
/*
    columnHelper.accessor('nourish_id', {
      header: 'nourish_id',
      //size: 40,
    }),
*/
    columnHelper.accessor('unitCode', {
      header: 'unitCode',
      //size: 40,
    }),

    columnHelper.accessor('date_of_birth', {
      header: 'date_of_birth',
      //size: 40,
    }),
/*
    columnHelper.accessor('id', {
      header: 'id',
      //size: 40,
    }),
    */
    columnHelper.accessor('nhs_number', {
      header: 'nhs_number',
      //size: 40,
    }),    
    /*
    columnHelper.accessor('residentID', {
      header: 'residentID',
      //size: 40,
    }),
    */
  
    /*
    columnHelper.accessor('s_ID', {
      header: 's_ID',
      //size: 40,
    }),
    columnHelper.accessor('s_Name', {
      header: 's_Name',
      //size: 40,
    }),
    columnHelper.accessor('s_archived', {
      header: 's_archived',
      //size: 40,
    }),
    columnHelper.accessor('s_code', {
      header: 's_code',
      //size: 40,
    }),
    columnHelper.accessor('s_db_code', {
      header: 's_db_code',
      //size: 40,
    }),
    columnHelper.accessor('s_db_fb_code', {
      header: 's_db_fb_code',
      //size: 40,
    }),
    columnHelper.accessor('s_demo', {
      header: 's_demo',
      //size: 40,
    }),
    columnHelper.accessor('site_id', {
      header: 'site_id',
      //size: 40,
    }),
    */
  ];

  export const columns_template_heartbeat_monitoring_calls = [

    columnHelper.accessor('unitStatus', {
      header: 'Status',
      size: 40,
      filterVariant: 'multi-select',
      filterSelectOptions: ['Active', 'Inactive'],
      Cell: ({ renderedCellValue, row })=>{
        if (renderedCellValue==="Active") 
          return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'inline-block' }}><GetHeartbeatIcons imgString={'YES'} /></span>
            <span style={{ display: 'inline-block', marginLeft: '5px' }}>Active</span>
          </div>);
        else if (renderedCellValue==="Inactive")
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ display: 'inline-block' }}><GetHeartbeatIcons imgString={'NO'} /></span>
              <span style={{ display: 'inline-block', marginLeft: '5px' }}>Inactive</span>
            </div>);
        return (<div>NULL</div>);
      },
    }),
  
    columnHelper.accessor('unitId', {
      header: 'Unit ID',
      size: 40,
    }),
  
    columnHelper.accessor('unitName', {
      header: 'Room',
      size: 40,
    }),
  
    columnHelper.accessor('unitZone', {
      header: 'Zone',
      size: 40,
    }),
  
    columnHelper.accessor('lastTime', {
      header: 'Last reported',
      size: 40,
    }),
  
  ];

  export const columns_template_groups = [
    columnHelper.accessor('devicegroupName', {
      header: 'Group Name',
      size: 40,
    }),
    columnHelper.accessor('deviceGroupID', {
      header: 'Group ID',
      size: 120,
    }),
    columnHelper.accessor('groupDevices', {
      header: 'Group Devices',
      size: 120,
    }),
  ];


  export const columns_template_integrations = [
    columnHelper.accessor('integrationType', {
      header: 'Integration',
      size: 120,
    }),
    // columnHelper.accessor('integrationID', {
    //   header: 'Integration ID',
    //   size: 120,
    // }),
    columnHelper.accessor('integrationKey', {
      header: 'API key',
      size: 120,
    }),
    columnHelper.accessor('integrationEnabled', {
      header: 'Status',
      size: 40,
    })
  ];

  
  export const columns_template_zone_profiles = [
    columnHelper.accessor('id', {
      header: 'id',
      size: 40,
    }),    

    columnHelper.accessor('name', {
      header: 'Profile name',
      size: 40,
    }),
    columnHelper.accessor('zones', {
      header: 'Zones',
      size: 40,
      Cell: ({ renderedCellValue, row }) => (
        <div>
          {(renderedCellValue)&&
          renderedCellValue.map(z=>{
            return (
              <div className={["zone_profile_zone_summary_container", (z.zoneSelected==false)&&"strikethrough-text"].join(' ')}>
                <div><b>{z.name}</b></div>
                <div className='m-5'>
                  <div className={(z.emgSelected==false)&&"strikethrough-text"}>Emergency</div>
                  <div className={(z.callSelected==false)&&"strikethrough-text"}>Call</div>
                  <div className={(z.accSelected==false)&&"strikethrough-text"}>Accessory</div>
                  <div className={(z.assSelected==false)&&"strikethrough-text"}>Assistance</div>
                  <div className={(z.attSelected==false)&&"strikethrough-text"}>Attendance</div>
                </div>
              </div>
            );
          })
          }
        </div>
      ),
    }),
  ];

  export const columns_template_zone_profile_zones = [
    columnHelper.accessor('id', {
      header: 'id',
      size: 40,
    }),    
    columnHelper.accessor('name', {
      header: 'Zone',
      size: 40,
    }),    
    columnHelper.accessor('zoneSelected', {
      header: 'Selected',
      size: 40,
      Cell: ({ renderedCellValue, row }) => (
      <ZoneProfileCheckBox field={"zoneSelected"} zoneID={row.original.id} initialValue={renderedCellValue} />
      ),
    }),    
    columnHelper.accessor('accSelected', {
      header: 'Accessory',
      size: 40,
      Cell: ({ renderedCellValue, row }) => <ZoneProfileCheckBox field={"accSelected"} zoneID={row.original.id} initialValue={renderedCellValue} />,
    }),    
    columnHelper.accessor('assSelected', {
      header: 'Assistance',
      size: 40,
      Cell: ({ renderedCellValue, row }) => <ZoneProfileCheckBox field={"assSelected"} zoneID={row.original.id} initialValue={renderedCellValue} />,
    }),    
    columnHelper.accessor('attSelected', {
      header: 'Attendance',
      size: 40,
      Cell: ({ renderedCellValue, row }) => <ZoneProfileCheckBox field={"attSelected"} zoneID={row.original.id} initialValue={renderedCellValue} />,
    }),    
    columnHelper.accessor('callSelected', {
      header: 'Calls',
      size: 40,
      Cell: ({ renderedCellValue, row }) => <ZoneProfileCheckBox field={"callSelected"} zoneID={row.original.id} initialValue={renderedCellValue} />,
    }),    
    columnHelper.accessor('emgSelected', {
      header: 'Emergency',
      size: 40,
      Cell: ({ renderedCellValue, row }) => <ZoneProfileCheckBox field={"emgSelected"} zoneID={row.original.id} initialValue={renderedCellValue} />,
    }),    
  ];

  export const columns_template_user_management = [
    
    columnHelper.accessor('title', {
      header: 'Title',
      size: 20,
    }),
    columnHelper.accessor('firstName', {
      header: 'First name',
      size: 40,
    }),
    columnHelper.accessor('surname', {
      header: 'Surname',
      size: 40,
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      size: 40,
    }),
    columnHelper.accessor('selectedSite', {
      header: 'Selected Site',
      size: 40,
    }),
    columnHelper.accessor('role', {
      header: 'Role',
      size: 40,
    }),
    
    columnHelper.accessor('auth_token', {
      header: 'Active/Inactive',
      size: 40,
        Cell: ({ renderedCellValue, row }) => (
           <div>{(renderedCellValue===null) ? "Inactive" : "Active"}</div>
        ),
    })
    
  ];

  function nourishSentCheck(calls){
    try { 
      const filt = (calls.filter(c=>(c.nourishID!==undefined)));
      return (filt.length>0)?filt[0].nourishID:"";   
    } catch(e){ return ""; }
  }

  const IntegrationIcon = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" /></svg>;




  export const columns_template_all_livecalls = [   
    columnHelper.accessor('DeviceType', {
      header: 'Call Source',
      size: 40,
      filterSelectOptions: ["Room Unit", "Pendant", "Motion Point", "Sensor Mat Unit", "Doorbell", "Ceiling Pull Cord", "Door Contact", "Sense"],
    }),
    
    columnHelper.accessor('callType', {
      header: 'Call Types',
      size: 40,
    }),

    columnHelper.accessor('room', {
      header: 'Room',
      size: 40,
    }),
    
    columnHelper.accessor('Zone', {
      header: 'Zone',
      size: 40,
    }),

  columnHelper.accessor('start', {
    header: 'Start',
    size: 40,
    Cell: ({ renderedCellValue, row }) => (
      <div>{utcDateToHHMMSS(renderedCellValue)}</div>
    ),
  }),
    
  columnHelper.accessor('end', {
      header: 'End',
      size: 40,
      Cell: ({ renderedCellValue, row }) => (
        <div>{utcDateToHHMMSS(renderedCellValue)}</div>
    ),
  }),

    columnHelper.accessor('duration', {
        header: 'Duration',
        size: 40,
    }),

    columnHelper.accessor('Carer', {
      header: 'Carer',
      size: 40,
      Cell: ({ renderedCellValue }) =><div>{renderedCellValue}</div>
    }),

    columnHelper.accessor('care_delivered', {
      header: 'Care Delivered ',
      size: 40,
      Cell: ({ renderedCellValue, row,  }) => (          
        <div className='flex-row'>
        {
        (nourishSentCheck(row.original.calls))
        &&
        <div className='nourish_care_icon'>
        <Tooltip title={<div className="tooltipStyle">Journey sent to Nourish</div>}><div><IntegrationIcon /></div></Tooltip>              
        </div>
        }
        
          <CareClicksField careClicks={renderedCellValue} />
        </div>
      ),
    })
  ];
  

  export const columns_template_livecalls = [

      // columnHelper.accessor('callType', {
      //   header: 'Call Origin',
      //   size: 40,
      // }),
    columnHelper.accessor('DeviceType', {
      header: 'Call Source',
      size: 40,
      filterSelectOptions: ["Room Unit", "Pendant", "Motion Point", "Sensor Mat Unit", "Doorbell", "Ceiling Pull Cord", "Door Contact", "Sense"],
    }),
      
      columnHelper.accessor('callText', {
        header: 'Call Types',
        size: 40,
      }),

      columnHelper.accessor('room', {
        header: 'Room',
        size: 40,
      }),
      
      columnHelper.accessor('zone', {
        header: 'Zone',
        size: 40,
      }),

      columnHelper.accessor('calls', {
        header: 'Journey ',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
           <div>
            <JourneyBar journey={renderedCellValue}  totalSeconds={row.original.durationSeconds}  />
          </div>
        ),
      }),

    columnHelper.accessor('start', {
      header: 'Start',
      size: 40,
      Cell: ({ renderedCellValue, row }) => (
        <div>{utcDateToHHMMSS(renderedCellValue)}</div>
      ),
    }),
      
    columnHelper.accessor('end', {
        header: 'End',
        size: 40,
        Cell: ({ renderedCellValue, row }) => (
          <div>{utcDateToHHMMSS(renderedCellValue)}</div>
      ),
    }),

      columnHelper.accessor('duration', {
          header: 'Duration',
          size: 40,
      }),

      columnHelper.accessor('Carer', {
        header: 'Carer',
        size: 40,
        Cell: ({ renderedCellValue }) =><div>{renderedCellValue}</div>
      }),

      columnHelper.accessor('care_delivered', {
        header: 'Care Delivered',
        size: 40,
        Cell: ({ renderedCellValue, row,  }) => (          
          <div className='flex-row'>
          {
          (nourishSentCheck(row.original.calls))
          &&
          <div className='nourish_care_icon'>
          <Tooltip title={<div className="tooltipStyle">Journey sent to Nourish</div>}><div><IntegrationIcon /></div></Tooltip>              
          </div>
          }
          
            <CareClicksField careClicks={renderedCellValue} />
          </div>
        ),
      }),

/*
      columnHelper.accessor('clearType', {
        header: 'clearType',
        size: 40,
      }),
      columnHelper.accessor('end', {
        header: 'end',
        size: 40,
      }),
     columnHelper.accessor('AccessoryType', {
      header: 'AccessoryType',
      size: 40,
    }),
    columnHelper.accessor('aqRef', {
        header: 'aqRef',
        size: 40,
      }),
      columnHelper.accessor('beaconId', {
        header: 'beaconId',
        size: 40,
      }),
      columnHelper.accessor('journeyRef', {
        header: 'journeyRef',
        size: 40,
      }),
      columnHelper.accessor('locTx', {
        header: 'locTx',
        size: 40,
      }),

      columnHelper.accessor('panelRef', {
        header: 'panelRef',
        size: 40,
      }),  
      columnHelper.accessor('startFullDate', {
        header: 'startFullDate',
        size: 40,
      }),
      columnHelper.accessor('txCode', {
        header: 'txCode',
        size: 40,
      }),
      columnHelper.accessor('unitId', {
        header: 'unitId',
        size: 40,
      }),
    */
      
  ];

  function utcDateToHHMMYYYYHHMM(utcDate) {
    // Ensure utcDate is a valid Date object
    utcDate = new Date(utcDate);
    
    if (!(utcDate instanceof Date) || isNaN(utcDate)) {
        console.log('Invalid Date object');
        return utcDate;
    }

    const options = {
      timeZone: "Europe/London",
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    };
    
    // Create a formatter with the UK timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    
    // Format the UTC date to the UK timezone
    const ukDateStr = formatter.format(utcDate);
    return ukDateStr.replace(",", "");    
}

/*
  function utcDateToHHMMYYYYHHMM(utcDate) {
    // Ensure utcDate is a valid Date object
    utcDate = new Date(utcDate);
    
    if (!(utcDate instanceof Date) || isNaN(utcDate)) {
      console.log('Invalid Date object');
      return utcDate;
    }
  
    // Get UTC values
    const utcHours = utcDate.getUTCHours();
    const utcMinutes = utcDate.getUTCMinutes();
    const utcYear = utcDate.getUTCFullYear();
    const utcMonth = utcDate.getUTCMonth() + 1; // Months are zero-based
    const utcDay = utcDate.getUTCDate();
  
    // Format the values as two digits with leading zeros if needed
    const formattedHours = String(utcHours).padStart(2, '0');
    const formattedMinutes = String(utcMinutes).padStart(2, '0');
    const formattedYear = String(utcYear);
    const formattedMonth = String(utcMonth).padStart(2, '0');
    const formattedDay = String(utcDay).padStart(2, '0');
  
    // Construct the formatted string
    //const resultString = `${formattedHours}:${formattedMinutes} ${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
    const resultString = `${formattedDay}-${formattedMonth}-${formattedYear} ${formattedHours}:${formattedMinutes}`;
  
    return resultString;
  }
  */

  function utcDateToHHMMSS(utcDate) {
    // Ensure utcDate is a valid Date object
    utcDate = utcDate.toString();
    if (typeof utcDate === undefined) return '';
    if (typeof utcDate !== 'string') return '';

    let dattt = utcDate
    const options = {
      timeZone: "Europe/London",
/*      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
 */     hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    };
    // console.log(utcDate)
    var tmp = utcDate
    utcDate = new Date(utcDate);
    
    if (!isValidDate(utcDate)) {
        console.log("Invalid date:: ", utcDate)
        return '';
    }
      // console.log("disaodiou")
    if (!(utcDate instanceof Date) || isNaN(utcDate)) {
      // utcDate = "Tue Jul 23 2024 12:24:27 GMT+0100"
      
      console.log("InVLAID DATA", tmp)
      //throw new Error('Invalid Date object');
    }
    else{
      // console.log(tmp)
    }
     
    // Create a formatter with the UK timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);

    // Format the UTC date to the UK timezone
    const ukDateStr = formatter.format(utcDate);
    // console.log(ukDateStr)
    return ukDateStr;
  
}

function isValidDate(dt) {
  dt = new Date(dt);
  if (dt.toString() === "Invalid Date") return false;
  if (!(dt instanceof Date) || isNaN(dt)) {
    return false;
  }
  else {
    return true;
  }
}
  // else{
  //   var ss = utcDate.toString().split(':')
  //   console.log('mydate',utcDate)
  //   utcDate = new Date(null,null,null,parseInt(ss[0]),parseInt(ss[1]))
  //   const formatter = new Intl.DateTimeFormat('en-GB', options);
    
  //   // Format the UTC date to the UK timezone
  //   const ukDateStr = formatter.format(utcDate);
  //   // console.log(ukDateStr)
  //   return ukDateStr;
  // }
    
  