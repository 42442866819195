export function groupBy (xs, key) {
  try {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  catch (e) { return xs; }
};

export function getColour(callType){
  if (callType==="Emergency") return 'red';
  if (callType==="Attendance") return 'rgb(148, 202, 102)';
  if (callType==="Assistance") return '#F8DA3C';
  if (callType==="Call") return 'rgb(245, 129, 78)';
  if (callType==="Sense") return '#914397';
  if (callType==="Care") return 'rgb(225, 21, 131)';
  if (callType==="Accessory") return '#914397';
  return '';
}


export function getDaysArray(startDate, endDate) {
  // Create Date objects from input
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Initialize an empty array to hold the dates
  const dates = [];

  // Function to format the date as yyyy-mm-dd
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Iterate over the date range
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    // Push the formatted date to the dates array
    dates.push(formatDate(new Date(dt)));
  }

  // Return the array of formatted dates
  return dates;
}

export const groupByTwo=(list, keyGetter)=> {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}


export const kpiToSecs = (kpi) => {
  if (kpi = undefined) return 0;
  if (!isNaN(kpi)) return kpi;
  try {
      const k = kpi.split(":");
      const secs = parseInt(k[1]);
      const mins = parseInt(k[0]);
      let total = 0;
      
      if (!isNaN(secs)) total += secs;
      if (!isNaN(mins)) total += (mins * 60);
      
      return total;
  } catch(e){ 
      console.log("error", e);
      return 0;
  }
  
  return 0;
}

export const betweenDates = (dt, from, to) => {
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split(' ');
    const months = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
    };
    return new Date(year, months[month], day);
  };

  const date = parseDate(dt);
  const fromDate = parseDate(from);
  const toDate = parseDate(to);

  return date > fromDate && date < toDate;
};

export function getAverage(top, bottom) {
  let tmp = top/bottom;
  if (tmp.toString() =="NaN"){
    tmp = 0;
  };
  return tmp;
}


export function groupKeysBySubKey(obj, subKey) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const subKeyValue = value[subKey];
  
    if (!result[subKeyValue]) {
      result[subKeyValue] = {};
    }
  
    result[subKeyValue][key] = value;
    return result;
  }, {});
}